export function megaNav() {
  $(function () {
    $("#megaNavTrigger").each(function () {
      $(this).on("click", function () {
        $(this).addClass("is-open");
        $("#megaNavCloseTrigger").removeClass("is-close");
        $("#megaNav").addClass("is-show");
        $("#megaNav").removeClass("is-hide");
        $("body").addClass("is-fixed");
        return false;
      });
    });

    $("#megaNavCloseTrigger").each(function () {
      $(this).on("click", function () {
        $("#megaNavTrigger").removeClass("is-open");
        $(this).addClass("is-close");
        $("#megaNav").removeClass("is-show");
        $("#megaNav").addClass("is-hide");
        $("body").removeClass("is-fixed");
        return false;
      });
    });
  });
}
