export function spNav() {
  $(function () {
    $("#spNavTrigger").each(function () {
      $(this).on("click", function () {
        $(this).toggleClass("is-open");
        $("#spNav").toggleClass("is-show");
        $("body").toggleClass("is-fixed");
        $("header").toggleClass("nav-show");
        return false;
      });
    });
  });

  var navItem = document.getElementsByClassName("dropdownMenu-trigger");
  var i;
  for (i = 0; i < navItem.length; i++) {
    navItem[i].onclick = function () {
      this.classList.toggle("is-active");
      var parent = this.parentNode;
      parent.nextElementSibling.classList.toggle("is-show");
      var dropdownMenu = parent.nextElementSibling;
      if (dropdownMenu.style.maxHeight) {
        dropdownMenu.style.maxHeight = null;
        dropdownMenu.style.paddingTop = null;
      } else {
        dropdownMenu.style.maxHeight = dropdownMenu.scrollHeight + 33 + "px";
        dropdownMenu.style.paddingTop = "33px";
      }
    };
  }
}
