export function floatingBtn() {
  $(function () {
    var topBtn = $('#floatingBtn');
    $(window).scroll(function () {
      if ($(this).scrollTop() > 500) {
        topBtn.addClass('is-show');
      } else {
        topBtn.removeClass('is-show');
      }
    });
    //スクロールしてトップ
    topBtn.click(function () {
      $('body,html').animate(
        {
          scrollTop: 0,
        },
        100
      );
      return false;
    });
  });
}
